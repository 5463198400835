
import { Component, Vue } from 'vue-property-decorator';
import { usersForm } from '@/config/forms/users';

@Component({})
export default class ListUsers extends Vue {
  path = 'users';
  form = usersForm;
  selectInfo = {
    role: [],
  };
  headers = [
    {
      text: 'Nombre',
      value: 'name',
    },
    {
      text: 'Correo electrónico',
      value: 'email',
    },
    {
      text: 'Rol',
      value: 'role.name',
    },
    {
      text: 'Fecha de ingreso',
      value: 'dateHire',
    }, {
      text: 'Última conexión',
      value: 'lastLogin',
    }, {
      text: 'Creado',
      value: 'created',
    }, {
      text: 'Actions',
      value: 'actions',
      sortable: false,
    },
  ];

  async getRoles() {
    const req: any = await this.$http.get(`/roles/all`);
    this.selectInfo.role = req.data;
  }

  async mounted() {
    await this.getRoles();
  }
}
