const required = (v: any) => !!v || 'El campo es requerido';
export const usersForm = [
  {
    label: 'Nombres',
    type: 'V-text-field',
    rules: [required],
    field: 'name',
    format: 'col-12',
  },
  {
    label: 'Apellidos',
    type: 'v-text-field',
    rules: [required],
    field: 'lastname',
    format: 'col-12',
  },
  {
    label: 'Cédula',
    type: 'v-text-field',
    field: 'cc',
    format: 'col-12',
    mode: 'number',
  },
  {
    label: 'Correo electrónico',
    type: 'v-text-field',
    rules: [required],
    field: 'email',
    format: 'col-12',
  },
  {
    label: 'Télefono',
    type: 'v-text-field',
    field: 'phone',
    format: 'col-12',
    mode: 'number',
  },
  {
    label: 'Cargo',
    type: 'v-text-field',
    field: 'position',
    format: 'col-12',
  },
  {
    label: 'Roles',
    type: 'v-autocomplete',
    rules: [required],
    field: 'role',
    format: 'col-12',
    multiple: false,
  },
  {
    label: 'Contraseña',
    type: 'v-text-field',
    field: 'password',
    format: 'col-12',
    mode: 'password',
  },
  {
    label: 'Fecha de ingreso',
    type: 'v-list-item-title',
    format: 'col-12',
  },
  {
    label: 'Fecha de ingreso',
    type: 'v-date-picker',
    rules: [required],
    field: 'dateHire',
    format: 'col-12',
    mode: 'date',
  },
];
